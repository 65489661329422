import { Component, Inject } from '@angular/core';
import { OnInit } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmponboardingService } from '../../employee-onboarding/services/emponboarding.service';
import { HrserviceService } from '../../hr-manager/service/hrservice.service';
import { BusinessAppraisalComponent } from '../../appraisal/business-appraisal/business-appraisal.component';
// import { EmponboardingService } from '../../../services/emponboarding.service';

function amplussolarEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    if (email && !email.endsWith('@amplussolar.com')) {
      // If the email does not end with @amplussolar.com
      return { amplussolarEmail: true };
    }
    return null; // If the email is valid or the control is empty
  };
}

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  fb = new FormBuilder();
  departments: any;
  companies: any;
  managers: any;
  statuses: any;
  employmentTypes: any;
  hiringTypes: any;
  physicalLocations: any;
  businesses: any;
  umail: any;
  empId: any;
  email: any;
  personalInfoForm: FormGroup;
  professionalInfoForm: FormGroup;
  initials: any;;
  dialogRef: any;
  gotData: boolean = false;
  minDate: any;
  maxDate: any;
  on_roll_emp_id: any;
  off_roll_emp_id: any;
  jobTitles: any;
  exitReasons: any;
  genders: any;
  ic_tc_cl: any;
  transformedManagers: any;
  user_exists: boolean = true;




  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private empOnboardingService: EmponboardingService,
    private hrService: HrserviceService
  ) {

    this.personalInfoForm = this.fb.group({})
    this.professionalInfoForm = this.fb.group({
      employment_type: ['', Validators.required],
      emp_id: ['', [Validators.required]],
      initials: [''],
      job_title: ['', Validators.required],
      functional_designation: ['', Validators.required],
      doj: [null, Validators.required],
      physical_location: ['', Validators.required],
      manager: [null, Validators.required],
      department: ['', Validators.required], //get from employee table
      functional_department: ['', Validators.required],
      business: ['', Validators.required],
      company: ['', Validators.required],
      hiring_type: ['', Validators.required],
      status: [0, Validators.required],
      email: ["", [Validators.required, amplussolarEmailValidator()]],
      fname: ['', Validators.required],
      mname: ['', Validators.required],
      lname: ['', Validators.required],
      dob: [null, Validators.required],
      gender: ['', Validators.required],
      mobile_no: ['', Validators.required],
      total_experience: [''],
      solar_experience: [''],
      qualification_ug: [''],
      qualification_pg: [''],
      last_promotion_date: [null],
      resignation_date: [null],
      notice_period: [null],
      relieving_date: [null],
      exit_date: [null],
      exit_reason: [null],
      exit_remarks: [''],
      ic_tl_cl: [0]
    })


  }


  ngOnInit() {
    this.professionalInfoForm.get('employment_type')?.valueChanges.subscribe((value) => {
      if (value === 'On-Roll') {
        this.professionalInfoForm.get('emp_id')?.setValue(this.on_roll_emp_id);
      } else {
        this.professionalInfoForm.get('emp_id')?.setValue(this.off_roll_emp_id);
      }
    });
    this.umail = this.route.snapshot.queryParamMap.get('umail');
    this.email = this.route.snapshot.queryParamMap.get('email');
    this.empId = this.route.snapshot.queryParamMap.get('emp_id');

    this.hrService.getCompanies().subscribe((res: any) => {
      this.companies = res.data.companies;
      console.log(this.companies)
    })

    this.hrService.getDepartments().subscribe((res: any) => {
      this.businesses = res.data;
    })

    this.empOnboardingService.getEmpDefaultParams(this.email).subscribe((response: any) => {
      this.jobTitles = response.jobTitles;
      // this.departments = response.depts;
      // this.companies = response.companies;
      this.managers = response.managers;
      this.transformedManagers = this.managers.map((manager: any[]) => ({
        id: manager[0],
        name: manager[1]
      }));

      // this.businesses = response.businesses;
      let year = parseInt(response.date.slice(0, 4));
      let month = (response.date.slice(5, 7))
      let day = (response.date.slice(8, 10))
      this.minDate = `${year}-${month}-${day}`
      this.on_roll_emp_id = response.on_roll_emp_id;
      this.off_roll_emp_id = response.off_roll_emp_id;
      this.maxDate = `${year + 1}-${month}-${day}`
      this.statuses = response.statuses;
      this.employmentTypes = response.employmentTypes;
      this.hiringTypes = response.hiringTypes;
      this.physicalLocations = response.physicalLocations;
      this.genders = response.genders;
      this.initials = response.initials;
      this.exitReasons = response.exit_reason;
      this.ic_tc_cl = response.ic_tc_cl;
      this.user_exists = response.user_exists;

      this.getEmployeeInfo(this.empId);
      //   this.empOnboardingService.getProfile(this.umail, this.email).subscribe((response: any) => {
      //     // create controls in persolInfoForm for each key in response
      //     Object.keys(response).forEach((key) => {
      //       this.personalInfoForm.addControl(key, this.fb.control(null));
      //     });

      //     // this.personalInfoForm.get('emp_id')?.setValue();
      //     this.personalInfoForm.patchValue(response);
      //     this.personalInfoForm.disable();
      //     this.professionalInfoForm.get('emp_id')?.disable();
      //     this.gotData = true;
      //      (error: any) => {
      //       console.log(error);
      //     }
      //   })


    })

  }

  getEmployeeInfo(id) {
    this.hrService.employeeInfo({ emp_id: id, email: this.email }).subscribe((res: any) => {
      this.getDepartments(res.data.business);
      let exit_reason;
      if (res.data.exit_reason == "nan") {
        exit_reason = null;
      }
      else {
        exit_reason = res.data.exit_reason;
      }
      let manager;
      for (var i = 0; i < this.transformedManagers.length; i++) {
        if (res.data.manager == this.transformedManagers[i].id) {
          manager = this.transformedManagers[i].name;
        }
      }
      this.professionalInfoForm.patchValue({
        employment_type: res.data.employment_type,
        emp_id: id,
        initials: res.data.initials,
        job_title: res.data.job_title,
        functional_designation: res.data.functional_designation,
        doj: res.data.doj,
        physical_location: res.data.physical_location,
        manager: manager,
        department: res.data.department, //get from employee table
        functional_department: res.data.functional_department,
        business: res.data.business,
        company: res.data.company,
        hiring_type: res.data.hiring_type,
        status: res.data.status,
        email: res.data.email,
        fname: res.data.fname,
        mname: res.data.mname,
        lname: res.data.lname,
        dob: res.data.dob,
        gender: res.data.gender,
        mobile_no: res.data.mobile_no,
        total_experience: res.data.total_experience,
        solar_experience: res.data.solar_experience,
        qualification_ug: res.data.qualification_ug,
        qualification_pg: res.data.qualification_pg,
        last_promotion_date: res.data.last_promotion_date,
        resignation_date: res.data.resignation_date,
        notice_period: res.data.notice_period,
        relieving_date: res.data.relieving_date,
        exit_date: res.data.exit_date,
        exit_reason: exit_reason,
        exit_remarks: res.data.exit_remarks,
        ic_tl_cl: res.data.ic_tl_cl
      });
      console.log(res, "---------------------- employee info");
    })
  }

  getDepartments(id?) {
    console.log(id);
    console.log(this.professionalInfoForm.value.business);
    console.log("in get departments");
    let businessId;
    if (this.professionalInfoForm.value.business) {
      businessId = this.professionalInfoForm.value.business;
    }
    else {
      businessId = id;
    }
    for (var i = 0; i < this.businesses.length; i++) {
      if (this.businesses[i].id == businessId) {

        this.departments = this.businesses[i].departments;
        console.log(this.departments, '----departments')
      }
    }
    console.log(this.departments, '----departments')
  }

  onSubmit() {
    this.professionalInfoForm.get('emp_id')?.enable();
    console.log(this.professionalInfoForm.value)
    let manager;
    manager = this.professionalInfoForm.value.manager;
    this.professionalInfoForm.value.manager = manager.id;
    this.hrService.addEmployeeInfo({ emp_id: this.empId, email: this.email }, this.professionalInfoForm.value)
      .subscribe((response: any) => {
        this.ngOnInit();
        console.log(response);
        (error: any) => {
          console.log(error);
        }
      });
  }
}

