<span>
    <app-header-global></app-header-global>
</span>
<div class="main">
    <!-- *ngIf="gotData && !user_exists;else usrexist" -->
    <div class="content">
        <form [formGroup]="professionalInfoForm" class="personalInfo">

            <p class="upheading">Professional Information</p>

            <div class="border border-black p-3">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Employee's Details</span>
                    </div>
                </div>

                <div class="row">

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="">Employment Type</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="employment_type" class="infofield" required>
                                    <option value="">Select Employment Type</option>
                                    <option *ngFor="let type of employmentTypes" [value]="type[0]">{{ type[1] }}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('employment_type')?.errors?.['required'] && professionalInfoForm.get('employment_type')?.touched">
                                    Employment Type is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">

                        <div class="row">
                            <div class="col-sm-12">
                                <label>Employee ID</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="emp_id" class="infofield" required
                                    placeholder="Employee ID" disabled>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('emp_id')?.errors?.['required'] && professionalInfoForm.get('emp_id')?.touched">
                                    Employee ID is required.
                                </div>
                            </div>


                        </div>
                    </div>

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label for="jobTitleSelect">Job Title</label>
                            </div>

                            <div class="col-sm-12">
                                <select id="jobTitleSelect" class="infofield" formControlName="job_title">
                                    <option value="">Select Job Title</option>
                                    <option *ngFor="let title of jobTitles" [value]="title[0]">{{title[1]}}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('job_title')?.errors?.['required'] && professionalInfoForm.get('job_title')?.touched">
                                    Job Title is required.
                                </div>
                            </div>
                        </div>
                    </div>







                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Functional Designation</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="functional_designation" class="infofield" required
                                    placeholder="Functional Designation">
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('functional_designation')?.errors?.['required'] && professionalInfoForm.get('functional_designation')?.touched">
                                    Functional Designation is required.
                                </div>
                            </div>
                        </div>
                    </div>


                </div>


                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">E-Mail</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="email" formControlName="email" class="infofield" required
                                    placeholder="E-Mail" disabled>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('email')?.errors?.['required'] && professionalInfoForm.get('email')?.touched">
                                    E-Mail is required.
                                </div>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('email')?.errors?.['amplussolarEmail'] && professionalInfoForm.get('email')?.touched">
                                    E-Mail must be in the format of &#64;amplussolar.com.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Date of Birth -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Date of Joining</label>
                            </div>
                            <div class="col-sm-12">

                                <input id="doj" name="doj" required type="date" class="infofield"
                                    placeholder="Date of Joining" formControlName="doj" [min]="minDate"
                                    [max]="maxDate" />
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('doj')?.errors?.['required'] && professionalInfoForm.get('doj')?.touched">
                                    Date of Joining is required.

                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- Gender -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Physical Location</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="physical_location" class="infofield" required>
                                    <option value="">Select Physical Location</option>
                                    <option *ngFor="let location of physicalLocations" [value]="location[0]">{{
                                        location[1] }}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('physical_location')?.errors?.['required'] && professionalInfoForm.get('physical_location')?.touched">
                                    Physical location is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Manager</label>
                            </div>
                            <div class="col-sm-12">
                                <div class="search-container">
                                    <ng-select class="custom" [items]="transformedManagers" bindLabel="name"
                                        formControlName="manager" placeholder="Select Manager" [searchable]="true"
                                        [multiple]="false">
                                    </ng-select>
                                </div>

                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('manager')?.errors?.['required'] && professionalInfoForm.get('manager')?.touched">
                                    Manager is required.
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Company</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="company" class="infofield" required>
                                    <option value="">Select Company</option>
                                    <option *ngFor="let company of companies" [value]="company.company_id">{{ company.name}}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('company')?.errors?.['required'] && professionalInfoForm.get('company')?.touched">
                                    Company is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Business</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="business" class="infofield" required (change)="getDepartments()">
                                    <option value="">Select Business</option>
                                    <option *ngFor="let business of businesses" [value]="business.id">{{ business.name }}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('business')?.errors?.['required'] && professionalInfoForm.get('business')?.touched">
                                    Business is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Department</label>
                            </div>
                            <div class="col-sm-12">
                                <select id="desigSelect" class="infofield" formControlName="department">
                                    <option value="">Select Department</option>
                                    <option *ngFor="let dept of departments " [value]="dept.id">{{dept.name}}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('department')?.errors?.['required'] && professionalInfoForm.get('department')?.touched">
                                    Department is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Aadhar -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Functional Department</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="functional_department" class="infofield" required
                                    placeholder="Functional Department">

                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('functional_department')?.errors?.['required'] && professionalInfoForm.get('functional_department')?.touched">
                                    Functional Department is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Hiring Type</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="hiring_type" class="infofield" required>
                                    <option value="">Select Hiring Type</option>
                                    <option *ngFor="let type of hiringTypes" [value]="type[0]">{{ type[1] }}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('hiring_type')?.errors?.['required'] && professionalInfoForm.get('hiring_type')?.touched">
                                    Hiring Type is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Last Promotion Date</label>
                            </div>
                            <div class="col-sm-12">

                                <input id="doj" name="doj" required type="date" class="infofield"
                                    placeholder="Last Promotion Date" formControlName="last_promotion_date"
                                    [min]="minDate" [max]="maxDate" disabled/>
                                <!-- <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('last_promotion')?.errors?.['required'] && professionalInfoForm.get('last_promotion')?.touched">
                                    last_promotion is required.

                                </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Status <span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="status" class="infofield" required>
                                    <option value="">Status</option>
                                    <option *ngFor="let obj of statuses " [value]="obj[0]">{{obj[1]}}</option>
                                </select>
                                <!-- <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('ic_tl_cl')?.errors?.['required'] && professionalInfoForm.get('ic_tl_cl')?.touched">
                                    Exit Reason is required.</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p class="upheading">Personal Information</p>

            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Person's Details</span>
                    </div>
                </div>

                <div class="row">
                    <!-- Initials -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Initials</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="initials" class="infofield" required>
                                    <option value="">Select Initials</option>
                                    <option *ngFor="let initial of initials " [value]="initial[0]">{{initial[1]}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- First Name -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>First Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="fname" class="infofield" placeholder="First Name"
                                    required>
                            </div>
                        </div>
                    </div>
                    <!-- Middle Name -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Middle Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="mname" class="infofield" placeholder="Middle Name">
                            </div>
                        </div>
                    </div>
                    <!-- Last Name -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Last Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="lname" class="infofield" placeholder="Last Name"
                                    required>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <!-- Date of Birth -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Date of Birth</label>
                            </div>
                            <div class="col-sm-12">
                                <div class="d-flex input-wrapper">
                                    <input name="dob" required type="date" class="infofield" placeholder="Date of Birth"
                                        formControlName="dob" />
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- Gender -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Gender</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="gender" class="infofield" required>
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Personal Contact No.</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="mobile_no" name="mobile_no"
                                    placeholder="Personal Contact No." class="infofield" minlength="10" maxlength="10"
                                    pattern="\d*" required>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                     

            <!-- Qualification Details Section -->
            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Qualification Details</span>
                    </div>
                </div>
                <div class="row">
                    <!-- Qualification (up to UG) -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Qualification (up to UG)</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="qualification_ug" name="qualification_ug"
                                    placeholder="Qualification (up to UG)" class="infofield" required>

                            </div>
                        </div>
                    </div>
                    <!-- Qualification (PG & above) -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Qualification (PG & above)</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="qualification_pg" name="qualification_pg"
                                    placeholder="Qualification (PG & above)" class="infofield">

                            </div>
                        </div>
                    </div>
                    <!-- Professional Experience -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Professional Experience</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="total_experience" name="total_experience"
                                    placeholder="Professional Experience" class="infofield" required>

                            </div>
                        </div>
                    </div>
                    <!-- Solar Experience -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Solar Experience</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="solar_experience" name="solar_experience"
                                    placeholder="Solar Experience" class="infofield">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- bank details -->
            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Exit Details</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">On Notice Period <span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="notice_period" name="notice_period"
                                    placeholder="Notice Period" class="infofield" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('notice_period')?.errors?.['required'] && professionalInfoForm.get('notice_period')?.touched">
                                    Notice Period is required.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="  professionalInfoForm.get('status')?.value == 1">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Relieving Date</label>
                            </div>
                            <div class="col-sm-12">

                                <input id="relieving_date" name="relieving_date" required type="date" class="infofield"
                                    placeholder="Relieving Date" formControlName="relieving_date" [min]="minDate"
                                    [max]="maxDate" />
                                <!-- <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('relieving_date')?.errors?.['required'] && professionalInfoForm.get('relieving_date')?.touched">
                                    relieving_date is required.

                                </div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Exit Reason <span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="exit_reason" class="infofield" required>
                                    <option value="">Select Exit Reason</option>
                                    <option *ngFor="let reason of exitReasons " [value]="reason[0]">{{reason[1]}}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('exit_reason')?.errors?.['required'] && professionalInfoForm.get('exit_reason')?.touched">
                                    Exit Reason is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Exit Remarks <span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="exit_remarks" name="exit_remarks"
                                    placeholder="Exit Remarks" class="infofield" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('exit_remarks')?.errors?.['required'] && professionalInfoForm.get('exit_remarks')?.touched">
                                    Exit Remarks is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Exit Date</label>
                            </div>
                            <div class="col-sm-12">

                                <input id="exit_date" name="exit_date" required type="date" class="infofield"
                                    placeholder="Exit Date" formControlName="exit_date" [min]="minDate"
                                    [max]="maxDate" />
                                <!-- <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('exit_date')?.errors?.['required'] && professionalInfoForm.get('exit_date')?.touched">
                                    Exit Date is required.

                                </div> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="text-right">
            <!-- <button mat-button (click)="approveAll()" class="universalbtn" style="background-color: green;">Approve all
                fields</button> -->
            <button mat-button type="submit" class="universalbtn" 
                (click)="onSubmit()">Submit</button>
        </div>
    </div>


    <!-- <ng-template #usrexist>
        <div class="messagediv">
            <p>This user has been added to the  database.....</p> 
        </div>
     
    </ng-template> -->

</div>