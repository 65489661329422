import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get } from 'jquery';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HrserviceService {

  baseUrl = environment.url + "travelex_app/api/v2/";
  access_key: string = localStorage.getItem("accessToken");
  headers: any = new HttpHeaders()
    .set("Authorization", `${this.access_key}`)
    .set("Content-Type", "application/json");
  requestOptions: any = { headers: this.headers };
  apiUrl: any;
  employeeInfoUrl = 'employee/';
  companiesUrl = 'company_list/';
  departmentUrl = 'business_dep_list/'
  constructor(private http: HttpClient) { }


  EmployeeList(page: number, email: any) {
    this.apiUrl = `${this.baseUrl}admin/employees?page=${page}&email=`;
    const url = this.apiUrl;
    return this.http.get(url, this.requestOptions);
  }

  holidayList() {
    this.apiUrl = `${this.baseUrl}admin/holidays`;
    const url = this.apiUrl;
    return this.http.get(url, this.requestOptions);
  }

  addHolidayList(data: any) {
    this.apiUrl = `${this.baseUrl}admin/addHoliday`;
    const url = this.apiUrl;
    console.log(data);
    return this.http.post(url, data, this.requestOptions);
  }

  deleteHolidayList(id: number): Observable<any> {

    this.apiUrl = `${this.baseUrl}admin/delete_holiday/${id}`;
    const url = this.apiUrl;

    return this.http.delete(
      url, this.requestOptions

    );
  }

  addPatrnity(userId: number, leave_count: number) {
    this.apiUrl = `${this.baseUrl}admin/add_paternity`;
    const url = this.apiUrl;
    const body = {
      user_id: userId,
      leave_count: leave_count,
    }

    return this.http.post(url, body, this.requestOptions);
  }

  employeeInfo(params) {
    const httpOptions = {
      headers: this.headers,
      params: params
    };
    return this.http.get(this.baseUrl + this.employeeInfoUrl, httpOptions);
  }

  addEmployeeInfo(params, data) {
    const httpOptions = {
      headers: this.headers,
      params: params
    };
    return this.http.put(this.baseUrl + this.employeeInfoUrl, data, httpOptions);
  }

  getCompanies() {
    return this.http.get(this.baseUrl + this.companiesUrl, this.requestOptions);
  }

  getDepartments() {
    return this.http.get(this.baseUrl + this.departmentUrl, this.requestOptions);
  }
}
